// src/DataTable.js
import './components.css';
import React, { useState, useEffect, useRef} from "react";
import { Table, Button, Modal, Select, Form, Input, ConfigProvider, List, Card, Tag, Typography, Divider, Menu, Dropdown, Pagination, Checkbox, Tooltip } from 'antd';
// import Loading from "./Loading";
import { industryArray,areaArray,postArray,genderArray,findValueByKey,ageArray} from '../common';
import TextArea from 'antd/es/input/TextArea';
import { QuestionCircleOutlined } from '@ant-design/icons';
import ad_content from '../img/ad_content.png';
import ad_btn from '../img/ad_btn.png';

const AdvertiseDetail = () => {
  const [form] = Form.useForm();

  // const [isLoad,setIsLoad] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [usersArray,setUsersArray] = useState([]);
  const [filterUsersArray,setFilterUsersArray] = useState([]);
  const [isModal,setIsModal] = useState(false);
  const {Option} = Select;

  const [requestLimit,setRequestLimit] = useState(100);
  

  const [selectPost, setSelectPost] = useState(undefined);
  const [selectGender, setSelectGender] = useState(undefined);
  const [selectAge, setSelectAge] = useState(undefined);
  const [selectArea, setSelectArea] = useState(undefined);
  const [selectIndustry, setSelectIndustry] = useState(undefined);
  const [freeWord, setFreeWord] = useState(undefined);

  const [formValues, setFormValues] = useState({}); 
  const [isConfirm, setIsConfirm] = useState(false);
  const [isBtn, setIsBtn] = useState(false);

  const [sendAdNum,setSendAdNum] = useState(0);

  const [checkedItems, setCheckedItems] = useState({
    checkbox1: false,
    checkbox2: false,
  });

  const allChecked = Object.values(checkedItems).every(Boolean);

  const handleSearch = () => {
    setIsModal(true);
    setIsSearch(true);
  }

  const handleCancel = () => {
    setIsModal(false);
    setIsSearch(false);
    setIsConfirm(false);
  }

  const handleReset = () => {
    setSelectPost(undefined);
    setSelectGender(undefined);
    setSelectAge(undefined);
    setSelectArea(undefined);
    setSelectIndustry(undefined);
    setFreeWord(undefined);
    form.resetFields();
    setFilterUsersArray(usersArray);
    setIsModal(false);
    setIsSearch(false);

  }

  const hadnleSearch = () =>{
    let copyArray = usersArray;
    if(selectPost !== undefined){
      copyArray = copyArray.filter(user => user.post === selectPost);
    }
    if(selectGender !== undefined){
      copyArray = copyArray.filter(user => user.gender === selectGender);
    }
    if(selectAge !== undefined){
      copyArray = copyArray.filter(user => getDecadeKey(user.birthDate) === selectAge);
    }
    if(selectArea !== undefined){
      copyArray = copyArray.filter(user => user.area === selectArea);
    }
    if(selectIndustry !== undefined){
      copyArray = copyArray.filter(user => user.industry === selectIndustry);
    }
    if(freeWord !== undefined && freeWord !== ""){
      copyArray = copyArray.filter(user => 
        (user.businessSummary && user.businessSummary.includes(freeWord)) ||
        (user.introductionText && user.introductionText.includes(freeWord))
      );
    }
    setFilterUsersArray(copyArray);
    setIsModal(false);
    setIsSearch(false);
  }

  const handleCheckboxChange = (e) => {
    setCheckedItems({
      ...checkedItems,
      [e.target.name]: e.target.checked,
    });
  };

  useEffect(() => {
    init();
  },[]);

  const init = async()=>{
    // setIsLoad(true);
    await getUserInfo();
    await getAllUsers();
// setIsLoad(false);
  }

  const getUserInfo = async()=>{
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+"/profile/get/introducedData", {
          method: 'GET',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
          }
          else{
            console.log(data.userInfo)
            setUserInfo(data.userInfo);
            setRequestLimit(100);
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }
  }

  const getAllUsers = async()=>{
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+"/users/public", {
          method: 'GET',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
          }
          else{
            setUsersArray(data.usersArray)
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }
  }

  useEffect(() => {
    setFilterUsersArray(usersArray);
  },[usersArray]);

  useEffect(() => {
    if(userInfo) setSendAdNum(requestLimit - userInfo.adNum < filterUsersArray.length ? requestLimit - userInfo.adNum : filterUsersArray.length);
  },[filterUsersArray]);

  useEffect(() => {
    if(sendAdNum === 0 && userInfo) setSendAdNum(requestLimit - userInfo.adNum);
  },[userInfo]);

  function getDecadeKey(birthDate) {
    const today = new Date();
    const birth = new Date(birthDate);
    
    // 年齢を計算
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();
    
    // 誕生日がまだ来ていない場合、年齢を1引く
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
        age--;
    }
    
    // 年齢から年代を計算 (例: 27歳 => "20代", 35歳 => "30代")
    const decade = Math.floor(age / 10);
    
    return decade > 10 ? 10 : decade;
  }

  function reduceArrayRandomly(arr, targetLength) {
    if (targetLength >= arr.length) {
        return arr; // 配列の長さが既に目標以下の場合はそのまま返す
    }

    // 配列をシャッフル（Fisher-Yates アルゴリズム）
    const shuffled = arr.sort(() => Math.random() - 0.5);

    // 目標の長さにスライス
    return shuffled.slice(0, targetLength);
}

  const onSubmit = async(values) => {
    const LINEIDArray = reduceArrayRandomly(filterUsersArray.map(item => item.LINEID),sendAdNum);
    
    const postData = {
      LINEID: userInfo.LINEID,
      adTitle:values.adTitle,
      adText:values.adText,
      adBtnTxt:values.adBtnTxt,
      adNum:sendAdNum,
      LINEIDArray:LINEIDArray,
      isBtn:isBtn
    }
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+'/advertise', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData),
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            alert("エラーが発生しました");
          }
          else{
            if(data.isEntry){
              alert("エラーが発生しました");
            }
            else{
              alert("広告配信の依頼が完了しました");
              window.location.reload();
            }
          }
        }
    } catch (error) {
        alert("エラーが発生しました");
        console.error('Fetch error:', JSON.stringify(error));
    }
  };

  const onConfirm =(values)=>{
    if(sendAdNum <= 0){
      alert("配信人数が0人のため配信依頼できません。\n配信先を設定しなおしてください。")
    }
    else{
      setFormValues(values);
      setIsModal(true);
      setIsConfirm(true);
    }
  }

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: '#07DFD8',
              algorithm: true,
            },
            Input: {
              colorPrimary: '#07DFD8',
              algorithm: true,
            },
            Radio:{
              colorPrimary: '#07DFD8',
              algorithm: true,
            },
            Select: {
              colorPrimary: '#07DFD8',
              algorithm: true,
            },
            Checkbox: {
              colorPrimary: '#07DFD8',
              algorithm: true,
            },
          },
        }}
      >
        <Modal
          open={isModal}
          onCancel={() => handleCancel()}
          footer={[
            <>
              {isSearch &&
                <>
                  <div style={{textAlign:"start"}}>
                  <Form form={form}>
                  <Form.Item
                    name="post"
                    label={<span style={{fontSize:"120%"}}>役職</span>}
                    labelCol={{span:24}}
                  >
                   <Select
                        style={{fontSize:"120%", width: '100%'}}
                        allowClear
                        onChange={e=>setSelectPost(e)}
                        value={selectPost}
                    >
                      {postArray.map((item) => (
                          <Option value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Option>
                      ))}
                    </Select>
                  </Form.Item>
                <Form.Item
                  name="gender"
                  label={<span style={{fontSize:"120%"}}>性別</span>}
                  labelCol={{span:24}}
                >
                   <Select
                        style={{fontSize:"120%", width: '100%'}}
                        allowClear
                        onChange={e=>setSelectGender(e)}
                        value={selectGender}
                    >
                      {genderArray.map((item) => (
                          <Option value={item.key}>{item.value}</Option>
                      ))}
                    </Select>
                </Form.Item>
                <Form.Item
                  name="age"
                  label={<span style={{fontSize:"120%"}}>年代</span>}
                  labelCol={{span:24}}
                >
                   <Select
                        style={{fontSize:"120%", width: '100%'}}
                        allowClear
                        onChange={e=>setSelectAge(e)}
                        Value={selectAge}
                    >
                      {ageArray.map((item) => (
                          <Option value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Option>
                      ))}
                    </Select>
                </Form.Item>
                <Form.Item
                  name="area"
                  label={<span style={{fontSize:"120%"}}>活動拠点</span>}
                  labelCol={{span:24}}
                >
                    <Select
                        style={{fontSize:"120%", width: '100%'}}
                        allowClear
                        value={selectArea}
                        onChange={e=>setSelectArea(e)}
                    >
                        {areaArray.map((item) => (
                            <Option value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                  name="industry"
                  label={<span style={{fontSize:"120%"}}>業種</span>}
                  labelCol={{span:24}}
                >
                    <Select
                        style={{fontSize:"120%", width: '100%'}}
                        allowClear
                        onChange={e=>setSelectIndustry(e)}
                        value={selectIndustry}
                    >
                        {industryArray.map((item) => (
                            <Option value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                  name="free"
                  label={<span style={{fontSize:"120%"}}>フリーワード</span>}
                  labelCol={{span:24}}
                >
                  <Input
                    style={{fontSize:"120%"}}
                    onChange={e=> setFreeWord(e.target.value)}
                    value={freeWord}
                    type="text"
                  />
                </Form.Item>
                </Form>
                  </div>
                  <Button type='primary' onClick={() => hadnleSearch()}>
                    絞り込む
                  </Button>
                  <Button key="cancel" onClick={() => handleReset()}>
                    リセット
                  </Button>
                </>
              }
              {isConfirm &&
                <>
                {
                  <div style={{textAlign:"start"}}>
                    <div>下記の内容でお間違いないですか？</div>
                    <div>　</div>
                    {/* <div>【広告タイトル】</div>
                    <div> {formValues.adTitle}</div> */}
                    <div>【広告内容】</div>
                    <div style={{whiteSpace:"pre-wrap"}}>{formValues.adText}</div>
                    <div>　</div>
                    {!isBtn &&
                      <>
                        <div>【広告ボタンの文言】</div>
                        <div> {formValues.adBtnTxt}</div>
                        <div>　</div>
                      </>
                    }
                    <div>【広告配信人数】</div>
                    <div>{sendAdNum}人</div>
                    <div>　</div>
                  </div>
                }
                  <Button onClick={() => handleCancel()}>
                    閉じる
                  </Button>
                  <Button type="primary" onClick={() => onSubmit(formValues)}>
                    広告配信を依頼する
                  </Button>
                </>
              }
            </>
          ]}
        >
      </Modal>
      {/* <Loading loading={isLoad}/> */}
      <div style={{fontSize:"90%"}}>サービス・イベント・案件等の広告をおつなぎくんの公式ライン上にメッセージとして配信することができます。</div>
      <div style={{fontSize:"90%"}}>本ページで配信依頼をしていただき、運営による審査のあと公式ライン上に広告メッセージが配信されます。</div>
      <div style={{fontSize:"90%"}}>広告は上限人数までは何回でも配信できます。</div>
      <div style={{fontSize:"90%"}}>記載方法の詳細は<QuestionCircleOutlined style={{color:"#07DFD8"}}/>をタップしてご覧ください。</div>
      <div style={{marginTop:"15px",fontSize:"120%"}}>今月の広告配信人数合計：<span style={{fontWeight:"bold"}}>{userInfo && (<>{userInfo.adNum} / {requestLimit}</>)}</span></div>
      {userInfo &&
        <>
          {(requestLimit <= userInfo.adNum) ? (
          <>
            <div style={{color:"red"}}>広告配信数が上限に達しました。</div>
            <div>次回更新日の{(new Date(userInfo.planNextDate)).getFullYear() + "/" + ((new Date(userInfo.planNextDate)).getMonth()+1) + "/" + (new Date(userInfo.planNextDate)).getDate()}までお待ちください。</div>
          </>
        ) : (
          <div style={{margin:"20px 0"}}>
            <div style={{textAlign:"center"}}>
              <Button type="primary" style={{margin:"5px 0"}} onClick={() => handleSearch()}>
                広告の配信先を絞り込む
              </Button>
              {requestLimit - userInfo.adNum < filterUsersArray.length ?  <div>配信予定人数：{filterUsersArray.length}(<span style={{color:"red"}}>{sendAdNum}</span>)人</div> : <div>配信予定人数：{sendAdNum}人</div>}
            </div>
            {requestLimit - userInfo.adNum < filterUsersArray.length && 
              <>
                <div style={{marginTop:"5px",color:"red"}}>※配信上限数を超えているため、{filterUsersArray.length}人の中からランダムで{sendAdNum}人に配信されます。</div>
                <div>さらに配信先を絞り込むことをおすすめします。</div>
              </>
            }
            
            <Form
              name="form"
              className="page-form"
              style={{margin:"10px auto"}}
              onFinish={onConfirm}
              layout="horizontal"
            >
              {/* <Form.Item
                name="adTitle"
                rules={[{ required: true, message: '' }]}
                label={
                  <span style={{fontSize:"120%"}}>
                    広告タイトル
                    <Tooltip title={
                        <div style={{ textAlign: "center" }}>
                          <img
                            src="https://via.placeholder.com/150"
                            alt="広告タイトル例"
                            style={{ width: "100px", marginBottom: "10px" }}
                          />
                          <p>広告タイトルの例をここに表示します</p>
                        </div>
                      }>
                      <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                  </span>
                }
                labelCol={{span:24}}
                initialValue={userInfo.adTitle}
              >
                <Input
                  style={{fontSize:"120%"}}
                  type="text"
                />
              </Form.Item> */}
              <Form.Item
                name="adText"
                rules={[{ required: true, message: '広告内容を入力してください' }]}
                label={
                  <>
                    <span style={{marginRight:"10px",fontSize:"120%"}}>広告内容</span>
                    <Tooltip title={
                      <>
                        <p>広告内容に記載された内容は下記画像の赤枠の部分のように公式ラインのメッセージとして表示されます</p>
                        <div style={{ textAlign: "center" }}>
                          <img
                            src={ad_content}
                            alt="広告内容例"
                            style={{ width: "100px", marginBottom: "10px" }}
                          />
                          
                        </div>
                        </>
                      }>
                        <QuestionCircleOutlined style={{color:"#07DFD8"}}/>
                      </Tooltip>
                  </>
                }
                initialValue={userInfo.adText}
              >
                <TextArea rows={10} maxLength={1000}></TextArea>
              </Form.Item>
                <Form.Item
                  name="adBtnTxt"
                  rules={!isBtn ? [{ required: true, message: '広告ボタンの文言を入力してください' }] : []}
                  label={
                    <>
                      <span style={{marginRight:"10px",fontSize:"120%"}}>広告ボタンの文言</span>
                      <Tooltip 
                        title={
                          <>
                            <p>下記画像のボタンの文言を自由に設定できます。</p>
                            <p>ボタンを押したユーザーがいた場合、その方のご連絡先をお教えしますのでご連絡のほうお願いいたします。</p>
                            <p>また「広告ボタンを表示しない」にチェックを入れるとボタンは表示されません。</p>
                            <p>広告内に連絡先や日程調整のURL等を記載して直接連絡を取れる形にする場合はボタンを非表示にすることを推奨しております。</p>
                            <div style={{ textAlign: "center" }}>
                              <img
                                src={ad_btn}
                                alt="広告内容例"
                                style={{ width: "100px", marginBottom: "10px" }}
                              />
                              
                            </div>
                          </>
                        }
                      >
                        <QuestionCircleOutlined style={{color:"#07DFD8"}}/>
                      </Tooltip>
                    </>
                  }
                  initialValue={userInfo.adBtnTxt ? userInfo.adBtnTxt : "詳細を聞く"}
                >
                  <Input
                    style={{fontSize:"120%"}}
                    type="text"
                    disabled={isBtn}
                  />
                </Form.Item>
                <Checkbox
                    name="isBtn"
                    checked={isBtn}
                    onChange={()=>setIsBtn(!isBtn)}
                    style={{marginBottom:"50px"}}
                  >
                    広告ボタンを表示しない
                </Checkbox>

              <div style={{marginBottom:"20px"}}>
                <Checkbox
                  name="checkbox1"
                  checked={checkedItems.checkbox1}
                  onChange={handleCheckboxChange}
                >
                  犯罪につながる内容ではありません
                </Checkbox>
                </div>
                <div style={{marginBottom:"20px"}}>
                <Checkbox
                  name="checkbox2"
                  checked={checkedItems.checkbox2}
                  onChange={handleCheckboxChange}
                >
                  宗教・MLM等の内容ではありません
                </Checkbox>
              </div>
              <div style={{margin:"40px auto"}}>
                <div className="form-button">
                  <Button type="primary" htmlType="submit" disabled={!allChecked}>
                    確認へ
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        )
      }
        </>
      }
     </ConfigProvider>
    </>
   
  );
};

export default AdvertiseDetail;

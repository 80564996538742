// src/DataTable.js
import './components.css';
import React, { useState, useEffect, useLayoutEffect} from "react";
import { Table, Button, Modal, Select, Popover } from 'antd';
// import Loading from "./Loading";
import { useNavigate } from 'react-router-dom';

const statusArray =[
  {key:0,value:"承認済み"},
  {key:1,value:"承認待ち"},
  // {key:2,value:"未登録"}
]

const AdvertiseAdminTable = () => {
  const [adjustedHeight, setAdjustedHeight] = useState(0);

  // const [isLoad,setIsLoad] = useState(false);
  const [advertiseArray,setAdvertiseArray] = useState([]);
  const [isModal,setIsModal] = useState(false);
  const [selectIndex,setSelectIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const {Option} = Select;

  const [isDetail,setIsDetail] = useState(false);
  const [isAuth,setIsAuth] = useState(false);
  const [status,setStatus] = useState(0);
  const [message, setMessage] = useState("");

  const handleStatus = (value) =>{
    setStatus(value);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };


  const handleDetail = (index) => {
    setSelectIndex(index+(currentPage-1)*pageSize)
    setIsModal(true);
    setIsDetail(true);
  }

  const handleAuth = (index) => {
    setSelectIndex(index+(currentPage-1)*pageSize)
    setIsModal(true);
    setIsAuth(true);
  }


  const handleCancel = () => {
    setIsModal(false);
    setIsAuth(false);
    setIsDetail(false);
  }

  const updateIsAuth = async(isApprove) =>{
    setIsModal(false);
    setIsAuth(false);
    try {
      // setIsLoad(true);
        const response = await fetch(process.env.REACT_APP_API_DIR+'/advertise/approve', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({adId:advertiseArray[selectIndex].adId, isApprove:isApprove, approveMsg:message}),
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
            alert("エラーが発生しました");
          }
          else{
            if(isApprove){
              alert("承認しました");
            }
            else{
              alert("非承認しました");
            }
            
            window.location.reload();
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
        alert("エラーが発生しました");
    }
  }

  useEffect(() => {
    getAdvertise();
  },[]);

  const getAdvertise = async()=>{
    try {
      // setIsLoad(true);
        const response = await fetch(process.env.REACT_APP_API_DIR+"/advertise/list", {
          method: 'GET',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
          }
          else{
            setAdvertiseArray(data.adList)
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }
  }

  useEffect(() => {
    setCurrentPage(1);
  },[advertiseArray]);


  useLayoutEffect(() => {
    function setTableSize(){
      const bodyRect = document.querySelector('.ant-table-body').getBoundingClientRect();
      const htmlRect = document.documentElement.getBoundingClientRect();
      setAdjustedHeight(window.innerHeight-(bodyRect.y-htmlRect.top));
    }
    setTableSize();
  
    const handleResize = () => {
      setTableSize();
    };
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  const columns = [
    // { title: '名前', dataIndex: 'lastName', key: 'lastName',
    //   render: (data, record, index)=> {
    //     return (
    //       <>
    //       {advertiseArray[index+(currentPage-1)*pageSize] &&
    //         <>
    //           {data}{advertiseArray[index+(currentPage-1)*pageSize].firstName}
    //         </>
    //       }
    //       </>
    //     );
    //   }     
    // },
    { title: '申込日', dataIndex: 'createDate', key: 'createDate',
      render: (data, record, index)=> {
        return (
          <>
            {(new Date(data)).getFullYear() + "/" + ((new Date(data)).getMonth()+1) + "/" + (new Date(data)).getDate()}
          </>
        );
      }     
    },
    { title: 'LINEID', dataIndex: 'LINEID', key: 'LINEID'},
    { title: '広告タイトル', dataIndex: 'title', key: 'title'},
    { title: '広告ボタンテキスト', dataIndex: 'btnText', key: 'btnText'},
    { title: '配信人数', dataIndex: 'toLINEIDs', key: 'toLINEIDs',
      render: (data)=> {
        return (
          <span>
            {data.split(',').length}
          </span>
        );
      }      
    },
    { title: '広告内容', dataIndex: 'detail', key: 'detail',
      render: (data, record, index)=> {
        return (
          <Button type="primary" onClick={() => handleDetail(index)}>
            広告内容
          </Button>
        );
      }      
    },
    { title: '承認', dataIndex: 'isAuth', key: 'isAuth',
      render: (data, record, index)=> {
        return (
          <>
            {data ? (
              <></>
            ) : (
              <Button type="primary" onClick={() => handleAuth(index)} disabled={advertiseArray[index+(currentPage-1)*pageSize].isAuth < 0}>
                承認
              </Button>
            )}
          </>
        );
      }      
    }
  ];

  return (
    <>
        <Modal
          // title="詳細"
          open={isModal}
          onCancel={() => handleCancel()}
          footer={[
            <>
              {isDetail &&
                <div style={{wordWrap:"break-word"}}>
                  <div style={{textAlign:"start",whiteSpace:"pre-wrap"}}>{advertiseArray[selectIndex].contentText}</div>
                  <Button key="cancel" onClick={() => handleCancel()}>
                    閉じる
                  </Button>
                </div>
              }
              {isAuth &&
                <>
                  <div style={{textAlign:"start"}}>{advertiseArray[selectIndex].lastName}{advertiseArray[selectIndex].firstName}さんを承認しますか？</div>
                  <div style={{margin:"10px 0",textAlign:"start"}}>
                    <textarea
                      value={message}
                      onChange={e=>setMessage(e.target.value)} // 入力値を保存する
                      rows="5"
                      cols="30"
                    ></textarea>
                  </div>
                  <Button type='primary' onClick={() => updateIsAuth(true)}>
                    承認
                  </Button>
                  <Button type='default' onClick={() => updateIsAuth(false)}>
                    非承認
                  </Button>
                </>
              }
            </>
          ]}
        >
      </Modal>
      {/* <Loading loading={isLoad}/> */}
      <div>
        <div style={{width:"100%",background:"#aaa",textAlign:"end",padding:"5px 0"}}>
          <Select
            style={{minWidth:"100px",marginRight:"10px"}}
            onChange={e=>handleStatus(e)}
            value={status}
          >
            {statusArray.map((item) => (
                <Option value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Option>
            ))}
          </Select>
        </div>
        <div  style={{overflowX:"scroll"}}>
          <Table dataSource={advertiseArray} columns={columns} bordered scroll={{ x: true, y:adjustedHeight }} 
            pagination={{ position: ['topRight','bottomRight'],
              current: currentPage,
              pageSize: pageSize,
              total: advertiseArray.length
            }} 
            onChange={handleTableChange}/>
        </div>
        </div>
    </>
   
  );
};

export default AdvertiseAdminTable;

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProfileForm from './ProfileForm';
import Error from './Error';
import Success from './Success';
import SuccessFirst from './SuccessFirst';
import UsersListAdmin from './UsersListAdmin';
import PlanChange from './PlanChange';
import FriendAdd from './FriendAdd';
import Withdrawal from './Withdrawal';
import SuccessPay from './SuccessPay';
import PlanCancell from './PlanCancell';
import SuccessCancell from './SuccessCancell';
import FriendAddIntent from './FriendAddIntent';
import ReferrerUsersList from './ReferrerUsersList';
import SuccessPlanChange from './SuccessPlanChange';
import UsersList from './UsersList';
import Homepage from './Homepage';
import Advertise from './Advertise';
import AdvertiseAdmin from './AdvertiseAdmin';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage/>} />
        <Route path="/ProfileForm" element={< ProfileForm/>} />
        <Route path="/Error" element={< Error/>} />
        <Route path="/Success" element={< Success/>} />
        <Route path="/SuccessFirst" element={< SuccessFirst/>} />
        <Route path="/UsersListAdmin" element={<UsersListAdmin/>} />
        <Route path="/PlanChange" element={<PlanChange/>} />
        <Route path="/FriendAdd" element={<FriendAdd/>} />
        <Route path="/Withdrawal" element={<Withdrawal/>} />
        <Route path="/SuccessPay" element={< SuccessPay/>} />
        <Route path="/PlanCancell" element={<PlanCancell/>} />
        <Route path="/SuccessCancell" element={< SuccessCancell/>} />
        <Route path="/FriendAddIntent" element={<FriendAddIntent/>} />
        <Route path="/ReferrerUsersList" element={<ReferrerUsersList/>} />
        <Route path="/SuccessPlanChange" element={<SuccessPlanChange/>} />
        <Route path="/UsersList" element={<UsersList/>} />
        <Route path="/Advertise" element={<Advertise/>} />
        <Route path="/AdvertiseAdmin" element={<AdvertiseAdmin/>} />
      </Routes>
  </BrowserRouter>
  );
}

export default App;
